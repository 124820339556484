import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

const authInstance = new PublicClientApplication(msalConfig);

authInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    authInstance.setActiveAccount(account);
  }
});

export { authInstance };
