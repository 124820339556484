import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { AuthProvider } from "./features/authentication/AuthProvider";
import "./index.css";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
