import { StylesConfig } from "react-select";

import chroma from "chroma-js";

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
}

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});
const getColorOptions = (t: any) => [
  { value: "Unpaid", label: t("InvoiceStatus.Unpaid"), color: "#9A102E" },
  { value: "Paid", label: t("InvoiceStatus.Paid"), color: "green" },
  { value: "Void", label: t("InvoiceStatus.Void"), color: "#fcb930" },
  { value: "Carried", label: t("InvoiceStatus.Carried"), color: "lightBlue" },
  {
    value: "NothingDue",
    label: t("InvoiceStatus.NothingDue"),
    color: "lightGrey",
  },
];

const colourStyles: StylesConfig<ColourOption> = {
  control: (styles: any) => ({ ...styles, backgroundColor: "white" }),
  option: (
    styles: any,
    {
      data,
      isDisabled,
      isFocused,
      isSelected,
    }: {
      data: any;
      isDisabled: boolean;
      isFocused: boolean;
      isSelected: boolean;
    }
  ) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : data.color,
      cursor: "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  input: (styles: any) => ({ ...styles, ...dot() }),
  placeholder: (styles: any) => ({ ...styles, ...dot("#ccc") }),
  singleValue: (styles: any, { data }) => ({
    ...styles,
    ...dot(data.color),
  }),
};

export { getColorOptions, colourStyles };
