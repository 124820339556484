import { useQuery } from "react-query";

import { fetchInvoices } from "./InvoicesApi";

export const exampleKeys = {
  getInvoices: (pageIndex: number, pageSize: number) => [
    "invoices",
    pageIndex,
    pageSize,
  ],
};

export const useInvoices = (pageIndex: number, pageSize: number) => {
  const {
    isLoading: isLoadingInvoices,
    error,
    data: invoices,
  } = useQuery(exampleKeys.getInvoices(pageIndex, pageSize), () =>
    fetchInvoices(pageIndex, pageSize)
  );
  return { isLoadingInvoices, error, invoices };
};
