import * as React from "react";
import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { Props } from "react-currency-format";

import { useInvoices } from "./InvoicesQueries";
import { usePaginationContext } from "../../../common/components/pagination/resources/PaginationContext";

import Loading from "../../../common/components/loading/Loading";

import { IPaginatedResponse } from "../../../common/models/interfaces";
import { Invoice } from "../../../common/models/types";
import { invoiceSumType } from "../../../common/utils";

interface IInvoiceContext {
  invoices?: IPaginatedResponse<Invoice>;
  isLoadingInvoices?: boolean;
  invoiceSumsType: string;
  setInvoiceSumsType: Dispatch<SetStateAction<string>>;
}

export const InvoicesContext = React.createContext<IInvoiceContext | undefined>(
  undefined
);

export const useInvoiceContext = () => {
  const context = React.useContext(InvoicesContext);
  if (!context) {
    throw new Error(`useInvoiceContext must be used within an Invoice Context`);
  }
  return context;
};

export const useInvoicesContext = () => {
  const context = React.useContext(InvoicesContext);
  if (!context) {
    throw new Error(
      `useInvoicesContext must be used within an Invoices Context`
    );
  }
  return context;
};

export const InvoicesProvider: FunctionComponent<Props> = ({ children }) => {
  const { pageIndex, pageSize } = usePaginationContext();
  const { isLoadingInvoices, error, invoices } = useInvoices(
    pageIndex - 1,
    pageSize
  );
  const [invoiceSumsType, setInvoiceSumsType] = useState<string>(
    invoiceSumType.customer
  );

  const errorRequest = error as Error;

  const value = React.useMemo(
    () => ({
      invoices,
      isLoadingInvoices,
      invoiceSumsType,
      setInvoiceSumsType,
    }),
    [invoices, invoiceSumsType]
  );

  if (isLoadingInvoices) return <Loading />;
  if (errorRequest)
    return <div> An error has occurred: + {errorRequest.message}</div>;

  return (
    <InvoicesContext.Provider value={value}>
      {children}
    </InvoicesContext.Provider>
  );
};
