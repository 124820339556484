import React, { FunctionComponent, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import routes from "./routes/routes";
import interceptors from "./interceptors";

import PageLayout from "./common/layouts/PageLayout";
import Loading from "./common/components/loading/Loading";

interceptors.setupInterceptors();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: FunctionComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Router>
        <Suspense fallback={<Loading />}>
          <PageLayout>
            <Routes>
              {routes.map(({ path, Component }, id) => {
                return <Route key={id} path={path} element={<Component />} />;
              })}
            </Routes>
          </PageLayout>
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
