import client from "../../../api/client";
import { API_INVOICES } from "../../../common/models/constants";
import { IPaginatedResponse } from "../../../common/models/interfaces";
import { Invoice } from "../../../common/models/types";
import { getInvoicesPerSelectedMonth } from "./helpers/invoicesPerSelectedMonth";

export const fetchInvoicesPerDate = async (date: Date) => {
  const result = await client.get<IPaginatedResponse<Invoice>>(
    `${API_INVOICES}`
  );
  return getInvoicesPerSelectedMonth(date, result);
};

export const InvoicesApi = {
  invoicesPerDate: fetchInvoicesPerDate,
};
