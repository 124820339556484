import React, { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";

import { Divider } from "@bbprojects/react-components";

import Header from "./Header";
import PageWrapper from "./PageWrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "./Footer";

const classes = {
  wrapper: "flex h-screen overflow-hidden",
  children: "justify-self-end h-5/6",
  page: "w-full overflow-auto",
};

const PageLayout: FunctionComponent<any> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <Sidebar />
      <div className={classes.page}>
        <Header name={t("Header.Title")}></Header>
        <Divider />
        <PageWrapper>
          <div className={classes.children}>{children}</div>
        </PageWrapper>
        <Divider />
        <Footer />
      </div>
    </div>
  );
};

export default PageLayout;
