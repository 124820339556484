import client from "../../../api/client";
import { API_INVOICES } from "../../../common/models/constants";
import { IPaginatedResponse } from "../../../common/models/interfaces";
import { Invoice } from "../../../common/models/types";

export const fetchInvoices = async (pageIndex: number, pageSize: number) => {
  const result = await client.get<IPaginatedResponse<Invoice>>(
    `${API_INVOICES}?page=${pageIndex}&size=${pageSize}`
  );
  return result.data;
};

export const InvoicesApi = {
  invoices: fetchInvoices,
};
