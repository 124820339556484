import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

interface IPaginationContext {
  pageIndex: number;
  setPageIndex: Dispatch<SetStateAction<number>>;

  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
}

export const PaginationContext = createContext<IPaginationContext | undefined>(
  undefined
);

export const usePaginationContext = () => {
  const context = useContext(PaginationContext);

  if (!context) {
    throw Error(
      "usePaginationContext should be used within a PaginationContext"
    );
  }

  return context;
};

export const PaginationProvider: FunctionComponent = ({ children }) => {
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const value = useMemo(
    () => ({
      pageIndex,
      setPageIndex,
      pageSize,
      setPageSize,
    }),
    [pageIndex, pageSize]
  );

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};
