import React, { useState } from "react";

import i18n from "../../../i18n";

import { FormSelect } from "../formSelect/FormSelect";
import { LanguageEnum, languageOptions } from "./helpers/utils";

const LanguageSelect = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("langData") || LanguageEnum.NL
  );

  const onChangeLanguageHandler = (value: string) => {
    localStorage.setItem("langData", value);
    i18n.changeLanguage(value);
    setSelectedLanguage(value);
  };

  return (
    <FormSelect
      selected={selectedLanguage}
      onChange={(value: any) => onChangeLanguageHandler(value.id)}
      options={languageOptions}
    />
  );
};

export default LanguageSelect;
