import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Invoice } from "../../../common/models/types";

import { ColourOption, getColorOptions } from "../helpers";
import { useInvoicesPerDate } from "./InvoicePerDateAndStatusQueries";
import { useTranslation } from "react-i18next";

type SelectType = ColourOption | undefined;

interface IInvoicePerDateAndStatusContext {
  selectedOption: SelectType;
  setSelectedOption: Dispatch<SetStateAction<SelectType>>;

  selectedInvoice?: Invoice;
  setSelectedInvoice: Dispatch<SetStateAction<Invoice | undefined>>;

  startDate: Date;
  setStartDate: Dispatch<SetStateAction<Date>>;

  invoices?: Invoice[];
  isLoadingInvoices: boolean;
  error?: Error | null | unknown;
}

export const InvoicePerDateAndStatusContext = createContext<
  IInvoicePerDateAndStatusContext | undefined
>(undefined);

export const useInvoicePerDateAndStatusContext = () => {
  const context = useContext(InvoicePerDateAndStatusContext);

  if (!context) {
    throw Error(
      "useInvoicePerDateAndStatusContext should be used within a InvoicePerDateAndStatusContext"
    );
  }

  return context;
};

export const InvoicePerDateAndStatusProvider: FunctionComponent = ({
  children,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState<SelectType>();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>();

  const { t } = useTranslation();

  const { isLoadingInvoices, error, invoices } = useInvoicesPerDate(startDate);

  useEffect(() => {
    setSelectedOption(
      getColorOptions(t).find((el) => el.value === invoices?.[0]?.status)
    );
    setSelectedInvoice(invoices?.[0] ?? undefined);
  }, [invoices, t]);

  useEffect(() => {
    setSelectedInvoice(
      invoices?.find((el: Invoice) => el.status === selectedOption?.value) ??
        undefined
    );
  }, [selectedOption]);

  const value = useMemo(
    () => ({
      selectedOption,
      selectedInvoice,
      invoices,
      startDate,
      setStartDate,
      setSelectedInvoice,
      setSelectedOption,
      isLoadingInvoices,
      error,
    }),
    [selectedOption, selectedInvoice, startDate, isLoadingInvoices, invoices]
  );

  return (
    <InvoicePerDateAndStatusContext.Provider value={value}>
      {children}
    </InvoicePerDateAndStatusContext.Provider>
  );
};
