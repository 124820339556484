import React from "react";
import LanguageSelect from "../components/languageSelect/LanguageSelect";

const classes = {
  header:
    "p-6 flex items-center justify-between px-4 text-zinc-100  nm-inset-zinc-100 bg-primary h-6 text-secondary",
};

const Header = ({ name }: any) => {
  return (
    <div className={classes.header}>
      <p>{name}</p>
      <LanguageSelect />
    </div>
  );
};

export default Header;
