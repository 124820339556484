import { useQuery } from "react-query";

import { fetchInvoicesPerDate } from "./InvoicePerDateAndStatusApi";

export const exampleKeys = {
  getInvoicesPerDate: (date: Date) => ["invoices", date],
};

export const useInvoicesPerDate = (date: Date) => {
  const {
    isLoading: isLoadingInvoices,
    error,
    data: invoices,
  } = useQuery(exampleKeys.getInvoicesPerDate(date), () =>
    fetchInvoicesPerDate(date)
  );
  return { isLoadingInvoices, error, invoices };
};
