/**
 * Class Name Template Literal function, to easier create long or complex class names in components.
 * Note that any elements in the template that are not `string`s or `number`s are ignored.
 * Usage: cntl`className1 ${condition ? "className2" : "className3"} className4`
 * @param template An array of strings that are part of the template (["className1 ", " className4"] in the example)
 * @param templateElements An array of elements being interpolated into the template (the conditional in the example)
 * @return A string containing the class names, with consistent spacing,
 *         i.e., no newlines and a single space between class names.
 */
export const cntl = (
  template: TemplateStringsArray,
  ...templateElements: unknown[]
) =>
  template
    .reduce((sum, n, index) => {
      const templateElement = templateElements[index];
      if (["string", "number"].includes(typeof templateElement)) {
        return `${sum}${n}${templateElement}`;
      }
      return `${sum}${n}`;
    }, "")
    .trim()
    .replace(/\s{2,}/g, " ");

export const noUndefined = <T>(value: T | undefined): boolean =>
  value !== undefined;
