import React from "react";

import src from "../../assets/images/betabit_logo.png";

const classes = {
  footer: "text-sm w-fit flex justify-end",
};

const Footer = () => {
  return (
    <div className={classes.footer}>
      <div>
        <img src={src} alt={"Beatbit_logo"} className="w-28 mr-4" />
      </div>
    </div>
  );
};

export default Footer;
