import { SelectOption } from "@bbprojects/react-components";

export enum LanguageEnum {
  NL = "nl_NL",
  EN = "en_GB",
}

export const languageOptions: SelectOption[] = [
  { id: LanguageEnum.NL, value: LanguageEnum.NL, label: "Nederlands" },
  { id: LanguageEnum.EN, value: LanguageEnum.EN, label: "English" },
];
