import React, {
  createContext,
  FunctionComponent,
  useContext,
  useMemo,
} from "react";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

export interface IAccountContext {
  account: AccountInfo;
  instance: IPublicClientApplication;
  userRole?: string;
  appRoles?: string[];
}

type IAccount = {
  environment: string;
  homeAccountId: string;
  idTokenClaims: IiIdTokenClaims;
  localAccountId: string;
  name: string;
  tenantId: string;
  username: string;
};

type IiIdTokenClaims = {
  acr: string;
  at_hash: string;
  aud: string;
  auth_time: number;
  exp: number;
  extension_Roles: string;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  roles: string[];
  name: string;
  nbf: number;
  nonce: string;
  sub: string;
  tid: string;
  ver: string;
};
export const AccountContext = createContext<IAccountContext | undefined>(
  undefined
);

export const useAccountContext = () => {
  const context = useContext(AccountContext);

  if (!context) {
    throw Error("useAccountContext should be used within an AccountContext");
  }

  return context;
};

export const AccountProvider: FunctionComponent<any> = ({ children }) => {
  const { instance } = useMsal();

  const activeAccount = instance.getActiveAccount() as IAccount;
  const userRole = activeAccount?.idTokenClaims?.extension_Roles;
  const appRoles = activeAccount?.idTokenClaims?.roles;
  const account = instance?.getActiveAccount() as AccountInfo;
  const value = useMemo(
    () => ({
      account,
      instance,
      userRole,
      appRoles,
    }),
    [account, instance, userRole, appRoles]
  );

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};
