import React, { FunctionComponent } from "react";
import { InvoicePerDateAndStatusProvider } from "../../features/invoicePerSelectedMonth/resources";
import { InvoicesProvider } from "../../features/invoices/resources";
import { PaginationProvider } from "../components/pagination/resources/PaginationContext";

const PageWrapper: FunctionComponent<any> = ({ children }) => {
  return (
    <PaginationProvider>
      <InvoicesProvider>
        <InvoicePerDateAndStatusProvider>
          {children}
        </InvoicePerDateAndStatusProvider>
      </InvoicesProvider>
    </PaginationProvider>
  );
};

export default PageWrapper;
