import { lazy } from "react";

import { INVOICES, INVOICES_BY_ID } from "../common/models/constants";
import { IRoute } from "../common/models/interfaces";

const AllInvoices = lazy(() => import("../pages/AllInvoicesPage"));
const InvoiceDetails = lazy(() => import("../pages/InvoiceDetailsPage"));
const InvoiceSums = lazy(() => import("../pages/InvoiceSumsPage"));

const InvoicePerMonthPage = lazy(() => import("../pages/InvoicePerMonthPage"));
const NotFound = lazy(() => import("../pages/NotFoundPage"));

const routes: IRoute[] = [
  { path: "/", Component: InvoicePerMonthPage },
  { path: INVOICES, Component: AllInvoices },
  { path: `${INVOICES_BY_ID}/items`, Component: InvoiceDetails },
  { path: `${INVOICES_BY_ID}/sums`, Component: InvoiceSums },
  {
    path: `${INVOICES_BY_ID}/sumsPerSubscription`,
    Component: InvoiceSums,
  },
  { path: "*", Component: NotFound },
];

export default routes;
