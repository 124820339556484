/* eslint-disable */

import { HoverDark, Icon } from "@bbprojects/react-components";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDoubleUpIcon } from "@heroicons/react/solid";
import { ReactNode } from "react";
import { cntl } from "./utils";

const buttonCN = (disabled?: boolean) => cntl`
    flex
    text-base
    items-center
    rounded
    border
    border-white
    shadow
    w-full
    py-1
    px-2
    z-20
    ${disabled ? "bg-disabled" : "bg-primary"}
`;

const optionsCN = cntl`
    absolute
    right-0
    z-10
    bg-white
    shadow-lg
    rounded
    w-28
    max-h-64
    mt-1
    overflow-auto
`;

const optionCN = (selected: boolean, active: boolean) => cntl`
    flex
    items-center
    py-2
    select-none
    ${active ? "bg-opacity-darker" : ""}
    ${selected ? "bg-opacity-darker font-semibold" : ""}
`;

export type SelectOption = {
  id: string;
  label: string;
  value: any;
};

interface SelectProps {
  renderOption?: (option: SelectOption) => ReactNode;
  selected?: string;
  onChange: (option: SelectOption) => void;
  options: SelectOption[];
  label?: string;
  disabled?: boolean;
}

const FormSelect = (props: SelectProps) => {
  const { renderOption, selected, onChange, options, label, disabled } = props;

  const selectedOption = options.find(({ id }) => id === selected);

  const displayOption = (option: SelectOption) =>
    renderOption?.(option) ?? option.label;

  return (
    <Listbox value={selectedOption} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div className="relative">
          {label && (
            <span className="py-1 text-sm font-semibold uppercase font-primary text-white">
              <Listbox.Label>{label}</Listbox.Label>
            </span>
          )}
          <Listbox.Button className={buttonCN(disabled)}>
            <div className="py-1 text-sm font-semibold uppercase font-primary text-white">
              {selectedOption && displayOption(selectedOption)}
            </div>
            <div className="h-5 ml-auto">
              <Icon Icon={ChevronDoubleUpIcon} color="white" />
            </div>
          </Listbox.Button>
          <Transition
            show={open}
            leave="transition duration-100 ease-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options static className={optionsCN}>
              {options.map((option) => (
                <Listbox.Option as="div" key={option.id} value={option}>
                  {({ selected, active }) => (
                    <HoverDark className="cursor-pointer">
                      <div className={optionCN(selected, active)}>
                        <div className="w-5 pl-3">
                          {selected && <Icon Icon={CheckIcon} />}
                        </div>
                        <div className="ml-4 mr-6 text-base text-black-lighter">
                          {displayOption(option)}
                        </div>
                      </div>
                    </HoverDark>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export { FormSelect };
