import { AxiosResponse } from "axios";
import { IPaginatedResponse } from "../../../../common/models/interfaces";
import { Invoice } from "../../../../common/models/types";

const getInvoicesPerSelectedMonth = (
  date: Date,
  invoices: AxiosResponse<IPaginatedResponse<Invoice>, any>
) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  return invoices.data.content.filter(
    (invoice: Invoice) =>
      new Date(invoice.invoiceDate).getMonth() === month &&
      new Date(invoice.invoiceDate).getFullYear() === year
  );
};

export { getInvoicesPerSelectedMonth };
