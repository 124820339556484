import React, { FunctionComponent } from "react";

const classes = {
  outerWrapper: "flex-none h-screen w-52 bg-sidebar",
  innerWrapper:
    "flex flex-col min-h-full overflow-auto group z-30 rounded-3xl nm-flat-zinc-100",
};

const SidebarLayout: FunctionComponent<any> = ({ children }) => {
  return (
    <div className={classes.outerWrapper}>
      <div className={classes.innerWrapper}>{children}</div>
    </div>
  );
};

export default SidebarLayout;
