import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import nl from "./translations/nl.json";

const resources = {
  nl_NL: {
    translation: nl,
  },
  en_GB: {
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en_GB",
  fallbackLng: "en_GB",
});

const languageData = localStorage.getItem("langData");

if (languageData) {
  i18n.changeLanguage(languageData);
}

export default i18n;
