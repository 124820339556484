import React, { FunctionComponent, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import {
  ButtonItem,
  IconChevronToggle,
  SidebarBody,
  TextDescriptor,
  TextIcon,
} from "@bbprojects/react-components";
import { Disclosure } from "@headlessui/react";
import {
  LogoutIcon,
  UserCircleIcon,
  UserGroupIcon,
  TableIcon,
  CalendarIcon,
} from "@heroicons/react/solid";

import SidebarLayout from "../../layouts/SidebarLayout";
import { useAccountContext } from "../../../features/authentication/AccountContext";

const classes = {
  textDescriptor: "ml-4 pt-8",
  menuDropdownButton: "flex items-center justify-between w-full",
  menuDropdownItems: "dropdown-items",
  sidebarMenu:
    "flex items-center justify-between w-full h-12 cursor-pointer px-4",
};

const sidebarItems = [
  { path: "/", text: "Sidebar.InvoicePerMonth", icon: CalendarIcon },
  { path: "/invoices", text: "Sidebar.AllInvoices", icon: TableIcon },
];

const Sidebar: FunctionComponent<any> = ({
  children,
}: PropsWithChildren<unknown>) => {
  const { account, instance, appRoles } = useAccountContext();

  const { t } = useTranslation();

  const renderMenuItems = () => {
    return (
      <>
        {sidebarItems.map((item) => (
          <Link to={item.path} key={item.path}>
            <ButtonItem text={t(item.text)} Icon={item.icon} />
          </Link>
        ))}
      </>
    );
  };

  return (
    <>
      <SidebarLayout>
        <div className="w-full">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <div className={classes.menuDropdownButton}>
                  <Disclosure.Button>
                    <div className={classes.sidebarMenu}>
                      <TextIcon
                        iconColor="primary"
                        text={account.name ?? ""}
                        Icon={UserCircleIcon}
                        iconSize={5}
                        textClassName="font-medium whitespace-nowrap"
                      />
                      <IconChevronToggle isOpen={open} right />
                    </div>
                  </Disclosure.Button>
                </div>
                <div className={classes.menuDropdownItems}>
                  <Disclosure.Panel>
                    <ButtonItem
                      Icon={UserGroupIcon}
                      text={appRoles?.toString() ?? ""}
                    />
                    <ButtonItem
                      Icon={LogoutIcon}
                      text={t("Header.SignOut")}
                      onClick={() => instance.logout()}
                    />
                  </Disclosure.Panel>
                </div>
              </>
            )}
          </Disclosure>
        </div>

        <SidebarBody>
          <TextDescriptor
            text={"Invoices"}
            className={classes.textDescriptor}
          />
          {renderMenuItems()}
          {children}
        </SidebarBody>
      </SidebarLayout>
    </>
  );
};

export default Sidebar;
