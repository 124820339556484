import { IconSpinner } from "@bbprojects/react-components";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const Loading: FunctionComponent = () => {
  const classes = {
    loader: "flex rounded w-full h-full items-center justify-center",
  };
  const { t } = useTranslation();

  return (
    <div className={classes.loader}>
      <IconSpinner color="primary" />
      <h4>{t("TableCommon.LoadingData")}</h4>
    </div>
  );
};

export default Loading;
