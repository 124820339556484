import React from "react";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { FunctionComponent, PropsWithChildren } from "react";
import { loginRequest } from "./authConfig";
import { authInstance } from "./authInstance";
import { AccountProvider } from "./AccountContext";

export const AuthProvider: FunctionComponent<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <MsalProvider instance={authInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <AccountProvider>{children}</AccountProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
